import React, { useState } from 'react';
import './ContactUsModal.css';

const ContactUsModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({ email: '', message: '' });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');

    try {
      const response = await fetch('https://www.kerambit.me/api/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to send your message. Please try again.');
      }

      setIsSubmitted(true);
      setTimeout(() => {
        setIsSubmitted(false);
        onClose();
      }, 2000); // Закрытие после 2 секунд
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="contact-modal-overlay">
      <div className="contact-modal">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h2>Get in touch today</h2>
        <p>Fill out the form below, and our team will contact you within one business day.</p>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            placeholder="example@email.com"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <label htmlFor="message">Leave us a message</label>
          <textarea
            name="message"
            placeholder="Please type your message here..."
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <button type="submit" className="send-button" disabled={isLoading}>
            {isLoading ? 'Sending...' : isSubmitted ? 'Message Sent!' : 'Send Message'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactUsModal;


