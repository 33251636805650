import React, { useState } from 'react';
import './footer.css';
import logo from '../assets/logo.png';
import ContactUsModal from './ContactUsModal'; // Модальное окно для контактов // Модальное окно для контактов
const Footer = () => {
  const [isContactOpen, setIsContactOpen] = useState(false);

  return (
    <footer className="footer">
      {/* Левая часть: Логотип и информация о компании */}


      {/* Центральная часть: Меню */}
      <div className="footer-center">
        <nav>
          <ul className="footer-menu">
            <li><a href="/">Home</a></li>
            <li><a href="/game">Shop</a></li>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
            <li><a href="/terms-and-conditions">Terms and Conditions</a></li>
            <li><a href="/CookiePolicy">Cookie Policy</a></li>
            <li><a className="contact-link" onClick={() => setIsContactOpen(true)}>Contact Us</a></li>
          </ul>
          <div className="footer-logo-comp">
             <div className="footer-paym-comp">
                <img src={logo} alt="Logo" className="footer-logo" />
                <p>
                 LIMEBRACE LIMITED<br />
                  No. 15977883<br />
                  Registration address: 35 Firs Avenue, London, England, N11 3NE<br />
                  Copyright © 2025. All Rights Reserved.
                </p>
              </div>
              <div className="footer-right">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Mastercard_2019_logo.svg/1200px-Mastercard_2019_logo.svg.png"
                  alt="MasterCard"
                  className="footer-payment-icon"
                />
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/Visa_2021.svg/640px-Visa_2021.svg.png"
                  alt="Visa"
                  className="footer-payment-icon"
                />
              </div>
            </div>
        </nav>
      </div>

      {/* Правая часть: Иконки платежных систем */}
  

      {/* Модальное окно для контактов */}
      <ContactUsModal isOpen={isContactOpen} onClose={() => setIsContactOpen(false)} />
    </footer>
  );
};

export default Footer;
