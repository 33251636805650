import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UserProfile.css';
import Footer from '../components/footer'; 
import avatar from '../assets/avatar-big.png'; 
import editIcon from '../assets/edit.png'; 
import MobNavbar from '../components/MobNav';
import loadingGif from '../assets/loading.gif';
import { Link, useNavigate } from 'react-router-dom';

const UserProfile = () => {
  const [userData, setUserData] = useState(null); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isEditing, setIsEditing] = useState(false); 
  const [updatedData, setUpdatedData] = useState({}); 
  const [isAdmin, setIsAdmin] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showAdminDropdown, setShowAdminDropdown] = useState(false);
  const [showTradeLinkError, setShowTradeLinkError] = useState(false);

  // timers[transactionId] = {
  //   waitingForTimer: boolean,
  //   loadingStart: number | null,
  //   checking: boolean,
  //   timerEnd: number | null  // когда таймер должен закончиться (Date.now() + ...)
  // }
  const [timers, setTimers] = useState({});
  const [loadingItem, setLoadingItem] = useState(null);

  const navigate = useNavigate();
  const toggleAdminDropdown = () => setShowAdminDropdown((prev) => !prev);
  const handleLogOut = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  //----------------------------------------------------------------
  // 1. Загрузка данных пользователя
  //----------------------------------------------------------------
  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        setError('You are not logged in');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get('https://www.kerambit.me/api/user/profile', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const user = response.data;
        // Гарантируем, что purchaseHistory точно будет массивом
        setUserData({ ...user, purchaseHistory: user.purchaseHistory || [] });
        setUpdatedData(user);

        // Проверяем, админ ли
        try {
          const accessResponse = await axios.get(
            'https://www.kerambit.me/api/admin/check-access',
            { headers: { Authorization: `Bearer ${token}` } }
          );
          if (accessResponse.status === 200) {
            setIsAdmin(true);
          }
        } catch {
          setIsAdmin(false);
        }
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Failed to fetch user data');
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();
  }, []);

  //----------------------------------------------------------------
  // 2. Восстановление / сохранение timers из localStorage
  //----------------------------------------------------------------
  useEffect(() => {
    const storedTimers = JSON.parse(localStorage.getItem('timers')) || {};
    setTimers(storedTimers);
  }, []);

  useEffect(() => {
    localStorage.setItem('timers', JSON.stringify(timers));
  }, [timers]);

  //----------------------------------------------------------------
  // 3. Логика изменения профиля
  //----------------------------------------------------------------
  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prev) => ({ ...prev, [name]: value }));
  };
  const handleSave = async () => {
    const token = localStorage.getItem('token');
    try {
      await axios.put('https://www.kerambit.me/api/user/profile', updatedData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserData(updatedData);
      setIsEditing(false);
      alert('Profile updated successfully');
    } catch (err) {
      console.error('Error updating profile:', err);
      alert('Failed to update profile');
    }
  };

  //----------------------------------------------------------------
  // 4. Логика получения товара
  //----------------------------------------------------------------
  const handleReceiveItem = async (transactionId, itemName, maxPrice, partner, token) => {
    if (!userData.steamtradelink) {
      setShowTradeLinkError(true);
      return;
    }

    // Показываем гифку
    setLoadingItem(transactionId);

    // Можем сразу проставить статус "Processing" (если логика бэкенда такова),
    // чтобы фронт не показывал повторную кнопку, пока идёт процесс.
    setUserData((prev) => ({
      ...prev,
      purchaseHistory: prev.purchaseHistory.map((it) =>
        it._id === transactionId ? { ...it, status: 'Processing' } : it
      ),
    }));

    // Помечаем, что у нас идёт 5-секундная задержка
    setTimers((prev) => ({
      ...prev,
      [transactionId]: {
        ...prev[transactionId],
        waitingForTimer: true,
        loadingStart: Date.now(),
        // checking и timerEnd пока не трогаем
      },
    }));

    // Запрос к серверу
    try {
      await axios.post(
        `https://www.kerambit.me/api/user/receive-item/${transactionId}`,
        { itemName, maxPrice, partner, token },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      console.log('Item request OK. Waiting 5s...');
      // Если сервер возвращает status="Processing" — можно тоже подхватить из ответа
      //   и сразу setUserData(...)
    } catch (error) {
      console.error('Ошибка при получении товара:', error);
      // Сброс, если сервер упал
      setLoadingItem(null);
      setTimers((prev) => ({
        ...prev,
        [transactionId]: {
          ...prev[transactionId],
          waitingForTimer: false,
          loadingStart: null,
        },
      }));
      // Опционально вернуть статус обратно, если запрос упал
      setUserData((prev) => ({
        ...prev,
        purchaseHistory: prev.purchaseHistory.map((it) =>
          it._id === transactionId ? { ...it, status: 'Not Received' } : it
        ),
      }));
    }
  };

  //----------------------------------------------------------------
  // 5. Проверка статуса покупки
  //----------------------------------------------------------------
  const checkPurchaseStatus = async (transactionId) => {
    try {
      const response = await axios.get(
        `https://www.kerambit.me/api/user/check-status/${transactionId}`,
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      const { stage } = response.data;

      // stage === 2 => Received
      if (stage === 2) {
        setUserData((prev) => ({
          ...prev,
          purchaseHistory: prev.purchaseHistory.map((item) =>
            item._id === transactionId ? { ...item, status: 'Received' } : item
          ),
        }));
        setTimers((prev) => ({
          ...prev,
          [transactionId]: { ...prev[transactionId], checking: false },
        }));
      }
      // stage === 5 => Timed Out
      else if (stage === 5) {
        setUserData((prev) => ({
          ...prev,
          purchaseHistory: prev.purchaseHistory.map((item) =>
            item._id === transactionId ? { ...item, status: 'Timed Out' } : item
          ),
        }));
        setTimers((prev) => ({
          ...prev,
          [transactionId]: { ...prev[transactionId], checking: false },
        }));
      }
      // Можно добавить stage === 1 => Processing, stage === 9 => some error, etc.
    } catch (error) {
      console.error('Ошибка при проверке статуса покупки:', error);
    }
  };

  //----------------------------------------------------------------
  // 6. Интервал: следим за 5 сек и таймером (timerEnd)
  //----------------------------------------------------------------
  useEffect(() => {
    const interval = setInterval(() => {
      setTimers((prev) => {
        const newTimers = { ...prev };

        for (const itemId of Object.keys(newTimers)) {
          const t = newTimers[itemId];
          if (!t) continue;

          // (A) 5-секундная задержка
          if (t.waitingForTimer && t.loadingStart) {
            const elapsed = Date.now() - t.loadingStart;
            if (elapsed >= 5000) {
              // 5 секунд прошли → стартуем 10-минутный таймер
              newTimers[itemId].waitingForTimer = false;
              newTimers[itemId].checking = true;
              newTimers[itemId].timerEnd = Date.now() + 600 * 1000; // 10 мин = 600сек

              // Если именно этот товар ещё грузит гифку
              if (loadingItem === itemId) {
                setLoadingItem(null);
              }
            }
          }

          // (B) Если checking === true, проверяем время
          if (t.checking && t.timerEnd) {
            const timeLeft = Math.floor((t.timerEnd - Date.now()) / 1000);
            if (timeLeft <= 0) {
              // Время вышло
              newTimers[itemId].checking = false;
              checkPurchaseStatus(itemId);
            }
          }
        }
        return newTimers;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [loadingItem]);

  // Следим за шириной окна
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  //----------------------------------------------------------------
  // 7. Рендер
  //----------------------------------------------------------------
  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="user-profile-page">
      <MobNavbar />

      {showTradeLinkError && (
        <div className="error-popup">
          <p>Trade link is not provided. Please update your profile with a valid Steam trade link.</p>
          <button onClick={() => setShowTradeLinkError(false)}>Close</button>
        </div>
      )}

      <div className="user-profile">
        {/* Блок с профилем */}
        <div className="profile-container">
          {/* Левая часть: аватар */}
          <div className="avatar-section">
            <img src={avatar} alt="User Avatar" className="avatar-image" />
          </div>

          {/* Правая часть: данные пользователя */}
          <div className="details-section">
            <h1 className="profile-title">Personal Details</h1>
            {userData && (
              <div className="details-info">
                <div className="info-field">
                  <span className="info-label">Name</span>
                  {isEditing ? (
                    <input
                      type="text"
                      name="username"
                      value={updatedData.username}
                      onChange={handleInputChange}
                      className="user-input"
                      placeholder="Enter your username"
                    />
                  ) : (
                    <span className="info-value">{userData.username}</span>
                  )}
                </div>
                <div className="info-field">
                  <span className="info-label">Email</span>
                  {isEditing ? (
                    <input
                      type="email"
                      name="email"
                      value={updatedData.email}
                      onChange={handleInputChange}
                      className="user-input"
                      placeholder="Enter your email"
                    />
                  ) : (
                    <span className="info-value">{userData.email}</span>
                  )}
                </div>
                <div className="info-field">
                  <span className="info-label">Link on Steam account</span>
                  {isEditing ? (
                    <input
                      type="text"
                      name="steamacclink"
                      value={updatedData.steamacclink || ''}
                      onChange={handleInputChange}
                      className="user-input"
                      placeholder="Enter your Steam link"
                    />
                  ) : (
                    <a
                      href={userData.steamacclink || '#'}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="info-value"
                    >
                      {userData.steamacclink || 'Not linked'}
                    </a>
                  )}
                </div>
                <div className="info-field">
                  <span className="info-label">Steam Trade Link</span>
                  {isEditing ? (
                    <input
                      type="text"
                      name="steamtradelink"
                      value={updatedData.steamtradelink}
                      onChange={handleInputChange}
                      className="user-input"
                      placeholder="Enter your Steam trade link"
                    />
                  ) : (
                    <span className="info-value">{userData.steamtradelink}</span>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* Кнопка Edit/Save */}
          <div className="edit-button">
            {isEditing ? (
              <button onClick={handleSave}>
                <img src={editIcon} alt="Save" />
                Save
              </button>
            ) : (
              <button onClick={handleEditToggle}>
                <img src={editIcon} alt="Edit" />
                Edit
              </button>
            )}
          </div>
        </div>

        {/* Блок с покупками */}
        <div className="transaction-section">
          <h2 className="transaction-title">Transactions</h2>
          <div className="transaction-items">
            {userData.purchaseHistory && userData.purchaseHistory.length > 0 ? (
              userData.purchaseHistory.map((item) => {
                // Достаём таймер (или {}) по ключу _id
                const t = timers[item._id] || {};

                // Вычисляем, сколько осталось секунд
                const getTimeLeft = () => {
                  if (!t.checking || !t.timerEnd) return 0;
                  const sec = Math.floor((t.timerEnd - Date.now()) / 1000);
                  return sec > 0 ? sec : 0;
                };
                const timeLeft = getTimeLeft();

                return (
                  <div
                    className="product-cart"
                    key={item._id}
                    data-rarity={item.product?.Rarity || 'Unknown'}
                  >
                    <div className="product-cart_inside">
                      <span className="product-game-quality-tag">
                        {item.product?.wear || 'Unknown'}
                      </span>
                      <div className="product-image-container">
                        <img
                          src={
                            item.product?.imageUrl?.startsWith('/images/')
                              ? item.product.imageUrl
                              : require(`../assets/${item.product?.imageUrl}`)
                          }
                          alt={item.product?.name || 'Unknown'}
                          className="product-game-image"
                        />
                      </div>
                      <div className="product-game-info">
                        <div className="product-game-name-category">
                          <span className="product-game-category">
                            {item.product?.category || 'Category'} • {item.product?.name || 'Unknown'}
                          </span>
                          <h4 className="product-name">
                            {item.product?.description || 'Description not available'}
                          </h4>
                        </div>
                        <p className="product-game-price">{item.price} €</p>
                        <p>Date: {new Date(item.purchaseDate).toLocaleDateString()}</p>
                        <p>Status: {item.status}</p>
                      </div>

                      <div className="product-game-footer">
                        {(() => {
                          // Если Received — показываем галочку
                          if (item.status === 'Received') {
                            return (
                              <div className="received-overlay">
                                <svg
                                  fill="#39a24b"
                                  height="50px"
                                  width="50px"
                                  viewBox="0 0 568.599 568.599"
                                >
                                  <path d="M565.692,147.211L507.96,89.479c-4.08-4.08-10.404-4.08-14.484,0L241.128,342.031L75.276,176.179 c-4.08-4.08-10.404-4.08-14.484,0L3.06,233.911c-4.08,4.08-4.08,10.404,0,14.484l230.724,230.724 c1.836,1.836,4.488,3.06,7.14,3.06s5.304-1.02,7.14-3.06l317.628-317.424C569.568,157.615,569.568,151.291,565.692,147.211z M241.128,457.495L24.684,241.051l43.248-43.248l165.852,165.852c4.08,4.08,10.404,4.08,14.484,0L500.82,111.103l43.248,43.248 L241.128,457.495z" />
                                </svg>
                              </div>
                            );
                          }

                          // Если Timed Out => кнопка
                          if (item.status === 'Timed Out') {
                            return (
                              <button
                                className="receive-button"
                                onClick={() =>
                                  handleReceiveItem(
                                    item._id,
                                    item.product?.name,
                                    item.price,
                                    userData.steamtradelink.partner,
                                    userData.steamtradelink.token
                                  )
                                }
                              >
                                Получить товар
                              </button>
                            );
                          }

                          // Если Payment not completed
                          if (item.paid === 'Not Paid') {
                            return <span className="payment-status">Payment not completed</span>;
                          }
                          if (item.paid === 'Pending') {
                            return <span className="payment-status">Payment is pending</span>;
                          }

                          // Если статус Processing => не показываем кнопку, а «зависим»
                          // от таймера (если уже пошёл) или показываем текст "Processing..."
                          if (item.status === 'Processing') {
                            // Если уже пошёл checking => можем показать таймер, или просто «Processing...»
                            if (t.checking && timeLeft > 0) {
                              const mm = Math.floor(timeLeft / 60);
                              const ss = timeLeft % 60;
                              return (
                                <span className="timer">
                                  Processing... {mm}:{ss < 10 ? '0' : ''}
                                  {ss}
                                </span>
                              );
                            }
                            // Иначе, если timeLeft = 0, но статус всё ещё Processing —
                            // значит ждём checkPurchaseStatus:
                            return <span className="processing-status">Processing...</span>;
                          }

                          // Если Not Received
                          if (item.status === 'Not Received') {
                            // (a) 5-секундная задержка → гифка
                            if (t.waitingForTimer) {
                              return (
                                <img
                                  src={loadingGif}
                                  alt="Loading..."
                                  className="loading-gif"
                                />
                              );
                            }
                            // (b) checking === true, timeLeft > 0 => таймер
                            if (t.checking && timeLeft > 0) {
                              const mm = Math.floor(timeLeft / 60);
                              const ss = timeLeft % 60;
                              return (
                                <span className="timer">
                                  Time left: {mm}:{ss < 10 ? '0' : ''}
                                  {ss}
                                </span>
                              );
                            }
                            // (c) checking === true, timeLeft=0 => ждём checkPurchaseStatus
                            if (t.checking && timeLeft === 0) {
                              return (
                                <button
                                  className="receive-button"
                                  onClick={() =>
                                    handleReceiveItem(
                                      item._id,
                                      item.product?.name,
                                      item.price,
                                      userData.steamtradelink.partner,
                                      userData.steamtradelink.token
                                    )
                                  }
                                >
                                  Получить товар
                                </button>
                              );
                            }
                            // (d) Нет задержки, нет checking => просто кнопка
                            return (
                              <button
                                className="receive-button"
                                onClick={() =>
                                  handleReceiveItem(
                                    item._id,
                                    item.product?.name,
                                    item.price,
                                    userData.steamtradelink.partner,
                                    userData.steamtradelink.token
                                  )
                                }
                              >
                                Получить товар
                              </button>
                            );
                          }

                          // Если ничего не подошло — default
                          return (
                            <button
                              className="receive-button"
                              onClick={() =>
                                handleReceiveItem(
                                  item._id,
                                  item.product?.name,
                                  item.price,
                                  userData.steamtradelink.partner,
                                  userData.steamtradelink.token
                                )
                              }
                            >
                              Получить товар
                            </button>
                          );
                        })()}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="no-transaction">You haven't made any purchases yet</p>
            )}
          </div>
        </div>

        {/* Панель админа */}
        {isAdmin && (
          <div className="button-row">
            <button className="admin-link" onClick={toggleAdminDropdown}>
              Admin Panel
            </button>
            {showAdminDropdown && (
              <div className="admin-dropdown">
                <Link to="/admin/add" className="admin-dropdown-link">
                  Add Items
                </Link>
                <Link to="/admin/products" className="admin-dropdown-link">
                  Edit Items
                </Link>
              </div>
            )}
            {isMobile && (
              <button onClick={handleLogOut} className="acc-logout-button">
                Log Out
              </button>
            )}
          </div>
        )}
      </div>

      {!isMobile && <Footer />}
    </div>
  );
};

export default UserProfile;
