import React, { useEffect } from "react";
import './CookiePolicy.css'; // Подключение стилей

const CookiePolicy = () => {
  useEffect(() => {
    // Добавляем скрипт Cookiebot
    const script = document.createElement("script");
    script.id = "CookieDeclaration";
    script.src = "https://consent.cookiebot.com/fdec80bb-9683-40d1-97f7-93a9c79c5137/cd.js";
    script.type = "text/javascript";
    script.async = true;
    script.dataset.culture = "en"; // Установите нужный язык (например, "ru")
    document.body.appendChild(script);

    // Удаляем элементы, добавленные скриптом
    const removeCookiebotElements = () => {
      // Удаляем сам скрипт
      const existingScript = document.getElementById("CookieDeclaration");
      if (existingScript) {
        existingScript.remove();
      }

      // Удаляем элементы декларации
      const cookieDeclarationContainer = document.querySelector("#CookieDeclaration");
      if (cookieDeclarationContainer) {
        cookieDeclarationContainer.innerHTML = ""; // Очищаем содержимое
      }

      // Удаляем баннер Cookiebot (если он присутствует)
      const cookieConsentBanner = document.querySelector(".CybotCookiebotDialog");
      if (cookieConsentBanner) {
        cookieConsentBanner.remove();
      }
    };

    // Чистим всё при размонтировании компонента
    return () => {
      removeCookiebotElements();
    };
  }, []);

  return (
    <div>
      <div id="CookieDeclaration"></div>
    </div>
  );
};

export default CookiePolicy;
