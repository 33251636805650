import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Footer from '../components/footer';
import './ProductDetails.css'; // Подключаем стили для страницы продукта
import MobNavbar from '../components/MobNav';

const ProductDetails = () => {
  const { id } = useParams(); // Получаем название продукта из URL
  const [product, setProduct] = useState(null);
  const [cart, setCart] = useState([]);

  const handleAddToCart = async (product) => {
    try {
      const token = localStorage.getItem('token');
      let updatedCart = [...cart];
  
      // Проверка, есть ли продукт уже в корзине
      const existingProductIndex = updatedCart.findIndex((item) => item._id === product._id);
  
      if (existingProductIndex !== -1) {
        // Если продукт уже в корзине, увеличиваем его количество
        updatedCart[existingProductIndex].quantity += 1;
      } else {
        // Если продукта нет, добавляем его с количеством 1
        updatedCart.push({ ...product, quantity: 1 });
      }
  
      if (token) {
        // Если пользователь авторизован, добавляем товар в корзину через API
        await axios.post(
          'https://www.kerambit.me/api/cart/add',
          { productId: product._id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
  
      // Локальное обновление состояния корзины
      setCart(updatedCart);
      localStorage.setItem('cart', JSON.stringify(updatedCart)); // Сохранение в локальном хранилище
  
      // Отправляем кастомное событие для обновления других компонентов
      const event = new Event('cartUpdate');
      window.dispatchEvent(event);
  
      console.log('Product added to cart successfully');
    } catch (error) {
      console.error('Error adding product to cart:', error);
    }
  };
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`https://www.kerambit.me/api/products/product/${id}`);
        setProduct(response.data);
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    fetchProduct();

    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  }, [id]);


  const handleIncreaseQuantity = (productId) => {
    const updatedCart = cart.map((item) => {
      if (item._id === productId) {
        return { ...item, quantity: item.quantity + 1 };
      }
      return item;
    });
  
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  
    // Отправляем кастомное событие для обновления других компонентов
    const event = new Event('cartUpdate');
    window.dispatchEvent(event);
  };
  
  const handleDecreaseQuantity = (productId) => {
    const updatedCart = cart.map((item) => {
      if (item._id === productId && item.quantity > 1) {
        return { ...item, quantity: item.quantity - 1 };
      }
      return item;
    });
  
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  
    // Отправляем кастомное событие для обновления других компонентов
    const event = new Event('cartUpdate');
    window.dispatchEvent(event);
  };
  const cartItem = product ? cart.find((item) => item._id === product._id) : null;

  return (
    <div className={`product-details-page `}>
      <MobNavbar/>
      {product ? (
        <div className="product-details-container">
          
          <div className="product-details-banner">
            <div className="product-details-image-container">
            <img
              src={
                product.imageUrl.startsWith('/images/') 
                  ? product.imageUrl  // Если путь начинается с /images/, используем его как есть
                  : require(`../assets/${product.imageUrl}`) // Для старых данных используем require
              }
              alt={product.name}
              className="product-game-image"
            />
              
            </div>
            <div className="product-details-info">
              <h1 className="product-details-title">{product.description}</h1>
              <p className="product-platform">{product.category} • {product.name}</p>
              <span className="product-game-quality-tag">{product.wear}</span>
              <p className="product-quantity">Stock: {product.stock}</p>
              <div className="product-price-section">
                <span className="product-price">{product.price} €</span>
              </div>
              <div className="product-details-actions">
                {cartItem ? (
                  <div className="quantity-controls">
                    <button onClick={() => handleDecreaseQuantity(product._id)}>-</button>
                    <span>{cartItem.quantity}</span>
                    <button onClick={() => handleIncreaseQuantity(product._id)}>+</button>
                  </div>
                ) : (
                <button className="add-to-cart-button" onClick={() => handleAddToCart(product)}>
                    <svg width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
                        <path fill="white" d="M0 .923C0 .4134.4146 0 .926 0h2.7782c.5114 0 .926.4133.926.923V6.25H23.074a.927.927 0 0 1 .7476.3782.9208.9208 0 0 1 .1349.8247l-3.3762 10.5769a.9259.9259 0 0 1-.8825.6432H3.7042c-.5115 0-.926-.4133-.926-.9231V1.8461H.926C.4146 1.8461 0 1.433 0 .9231Zm4.6302 7.1731v8.7307h14.3904l2.7869-8.7307H4.6302Zm2.0065 13.9808C6.6367 23.139 5.7729 24 4.7073 24c-1.0655 0-1.9293-.861-1.9293-1.9231 0-1.062.8638-1.923 1.9293-1.923 1.0655 0 1.9293.861 1.9293 1.923ZM17.2868 24c1.0655 0 1.9292-.861 1.9292-1.9231 0-1.062-.8637-1.923-1.9292-1.923-1.0655 0-1.9293.861-1.9293 1.923 0 1.0621.8638 1.9231 1.9293 1.9231Z" />
                    </svg>
                    Add to card
                </button>
                )}
            </div>
            </div>
          </div>
        </div>
      ) : (
        <p>Loading product...</p>
      )}

      <Footer />
    </div>
  );
};

export default ProductDetails;
